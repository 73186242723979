@import './base/setings.scss';
@import './components/PageField.scss';
@import './components/ModalForm.scss';
@import './components/Header.scss';
@import './components/Login.scss';

.SearchInput {
   flex: 1;
   width: 100%;
   padding-right: 1rem;

   .input{
      width: 100%;
   }
 }

 .react-datepicker-wrapper{
    width: 100%;
 }

 .letter-grid{
    div{
      background-color: #2185d0;
      color: white;
      height: 50px;
      font-size: 2.5rem;
      margin-top: 1rem;
      text-align: center;
      &:hover{
         background-color: darken(#2185d0, 30);
      }
    }
 }

 .resident-item{
    background-color: rgba(0,0,0, 0.05) !important;
    padding: 1rem !important;
    border-radius: 1rem !important;
    .resident-item-action{
       &:hover{
          text-decoration: underline;
          cursor: pointer;
       }
    }
 }

 .resident-item-white{
    background-color: white !important;
 }

 .home-payments-calendar{
   display: grid;
   grid-template-columns: 32% 32% 32%;
   gap: 1rem;

   .home-payments-calendar-item{
      border: 1px solid rgb(209, 209, 209);
      border-radius: 1rem;
      padding: 1rem;
      display: grid;
      grid-template-columns: 15% 75% 10%;

      span{
         font-size: 2rem;
         font-weight: bold;
      }
   }

   .home-payment-done{
      background-color: #65be3e;
      color: white;
   }
   .home-payment-pending{
      background-color: #f97557;
      color: white;
   }
 }


.PaymentTableHeader{
   display: grid;
   grid-template-columns: 3fr 3fr 3fr 1fr;
   border-bottom: 1px solid white;
   padding: 1rem;
   column-gap: 2rem;

   div{
      font-weight: bold;
   }
}

.PaymentTableBody .PaymentTableRow:nth-child(2n+1){
   // background-color: #f9f9f9;
}

.PaymentTableRow{
   display: grid;
   // grid-template-columns: 31.6% 31.6% 31.6% 5%;
   grid-template-columns: 3fr 3fr 3fr 1fr;
   column-gap: 2rem;
   padding: .5rem;
  
}
.PaymentTableFooter{
   margin-top: 1rem;
   display: flex;
   justify-content: space-between;

   section{
      padding: 1rem;
      font-size: 1.5rem;
      border-radius: 1rem;
      // border: 1px solid red;
      color: rgb(36, 36, 36);
      background-color: rgb(224, 224, 224);
   }
}

.PaymentFormSummary{
   display: flex;
   flex-direction: column;
   

   .PaymentFormSummaryRow{
      display: flex;
      justify-content: space-between;
      padding: .2rem 0;
   }

   .PaymentFormSummaryRow:last-child{
      display: flex;
      justify-content: space-between;
      padding: .2rem 0;
      font-size: 1.3rem;
      font-weight: bold;
   }
   
}

.PaymentTableActions{
   margin-top: 1rem;
}

.YearButton{
   cursor: pointer;
}

.PaymentTypeActions{
   display: flex;
   flex-direction: column;
   align-items: center;

   button{
      margin-top: 1rem !important;
      width: 40rem;
   }
}


 .VisitReportContainer {
    position: absolute;
   //  border: 1px solid red;
    height: 90%;

    .MessageBox {
      //  border: 1px solid blue;
      //  height: 5rem;
      margin-top: 1rem;
      margin-bottom: 0;
    }

    .TableBox {
      //  border: 1px solid green;
       height: calc(95% - 5rem);
       overflow-y: scroll;
    }
 }

.ConfigPage{

   .formSection{
      margin-bottom: 1rem;

      .formSectionBody{
         margin-left: 2rem;
      }
   }

   padding-bottom: 1rem;
 }


.Modal_form_field_input{
   // background-color: #f5f5f5 !important;
}

.Segment{
   margin-bottom: 1rem;
   margin-top: 1rem;
   border: 1px solid rgb(226, 226, 226);
   border-radius: 1rem;
   .SegmentTitle{
      padding: 1rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;   
      background-color: #f0f3f4;
      font-weight: bold;
   }

   .SegmentBody{
      padding: 1rem;
   }
}

.PaymentConfirmationPageInfo{
    > div {
      
      
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      > div:first-child {
         font-weight: bold;
         flex: 1;
      }
      > div:last-child {
         flex: 1;
      }
   }
}